/** @format */

@import '../../scss/_variables.scss';

.main {
  .container-fluid {
    padding: 0 20px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
a {
  color: $light_theme;
  transition: all 0.3s ease;
  &:hover {
    color: $blue;
  }
}
button {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn {
  &.btn-disable {
    opacity: 0.65;
  }
}
.font-normal {
  font-weight: 400 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.text-danger {
  color: $red !important;
}
.text-dark {
  color: $black !important;
}

.font-10 {
  font-size: $font-size-10 !important;
}
.font-11 {
  font-size: $font-size-11 !important;
}
.font-12 {
  font-size: $font-size-12 !important;
}
.font-13 {
  font-size: $font-size-13 !important;
}
.font-14 {
  font-size: $font-size-14 !important;
}
.font-15 {
  font-size: $font-size-15 !important;
}
.font-16 {
  font-size: $font-size-16 !important;
}
.font-17 {
  font-size: $font-size-17 !important;
}
.font-18 {
  font-size: $font-size-18 !important;
}
.font-20 {
  font-size: $font-size-20 !important;
}

.bg-success-outline {
  // color: #34c38f !important;
  background-color: rgba(52, 195, 143, 0.18) !important;
}
.bg-warning-outline {
  // color: #f1b44c !important;
  background-color: rgba(241, 180, 76, 0.18) !important;
}
.bg-danger-outline {
  // color: #f46a6a !important;
  background-color: rgba(244, 106, 106, 0.18) !important;
}

.sub-title {
  font-size: 16px;
  padding-bottom: 10px;
  position: relative;
  margin-top: 10px;
  &:after {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    left: 0px;
    bottom: 4px;
    background: $light_theme;
  }
}

.text-theme {
  color: #3271bc;
}

// table start

body {
  .table {
    td {
      .email-info {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-heading {
        text-align: left;
        min-width: 80px;
        max-width: 82px;
      }
      span.mb-0.info-text {
        text-align: left;
      }

      .info-heading {
        max-width: 82px;
      }
      .info-text {
        max-width: 200px;
      }
      &.id-table-tile {
        .info-heading {
          text-align: left;
          min-width: 90px;
          max-width: 90px;
        }
      }
      &.user-details-col {
        max-width: 300px;
      }
      &.contact-table-tile {
        .info-heading {
          text-align: left;
          min-width: 122px;
        }
        .info-text {
          text-align: left;
          min-width: 140px;
        }
      }
      .table-text-tile {
        font-size: 12px;
        margin-right: 5px;
        span {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    .personal-detail-heading {
      width: 180px;
      min-width: 180px;
    }
    .id-detail-heading {
      min-width: 190px;
      width: 190px;
    }

    .contact-detail-heading {
      min-width: 280px;
    }
    .mailing-detail-heading {
      min-width: 240px;
    }
    .thead-color {
      background: $white_variation4;
    }
    tbody {
      tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0.075);
        }
        &.no-hover {
          background-color: #f2f2f2;
          &:hover {
            background-color: #f2f2f2;
          }
        }
      }
    }
    tr {
      th {
        border-bottom: 2px solid rgba(120, 130, 140, 0.13);
      }
      td,
      th {
        &.text-field {
          max-width: 200px;
          min-width: 150px;
          word-break: break-word;
          word-wrap: break-word;
        }
        &.question-field {
          min-width: 300px;
          word-break: break-word;
          word-wrap: break-word;
        }
        &.subscription-col {
          max-width: 160px;
        }
        &.tag-col {
          min-width: 230px;
        }
        &.message-col {
          max-width: 350px;
          min-width: 150px;
        }
        &.message-min-col {
          min-width: 350px;
        }
        // &.text-field{
        //   max-width: 300px;
        // }
        padding: 8px;
        color: #000;
        position: relative;
        border-top: 0.1rem solid rgba(120, 130, 140, 0.13);
        transition: all 0.5s;
        vertical-align: middle;
      }
      .status-btn {
        font-size: 12px;
        padding: 5px;
      }

      .name-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }
    tbody {
      tr {
        td {
          border-top: 0;
        }
      }
    }
  }
}
// table end

// form start
.addon-input-wrap {
  .custom-reactselect {
    min-width: 80px;
  }
  .custom-inner-reactselect__control {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    &:hover {
      border-color: $grey_variation6 !important;
    }
  }
}
.form-group {
  position: relative;
  &.error-from-group {
    margin-bottom: 25px;
  }
}
.label-height {
  height: 19px;
  margin-bottom: 4px;
}
.custom-form-label {
  font-size: 14px;
  font-weight: 500;
  color: $black;
  line-height: normal;
  margin-bottom: 4px;
}
// .custom-form-input {
//   display: block;
//   width: 100%;
//   height: 40px;
//   padding: 10px 12px;
//   font-size: 14px;
//   font-weight: 400;
//   color: $main_light_black;
//   background-color: $white;
//   border: 1px solid $grey_variation6;
//   border-radius: 4px;
//   transition: all 0.15s ease-in-out;
//   &:focus,
//   &:hover {
//     box-shadow: none;
//     outline: none;
//     border: 1px solid $light_theme;
//   }
// }

.floating-label {
  position: absolute;
  pointer-events: none;
  margin-bottom: 0px;
  top: 4px;
  left: 5px;
  transition: 0.2s ease all;
  font-size: 14px;
  font-weight: 500;
  color: $black;
  &.top-label {
    top: -18px;
    color: $light_theme;
    font-size: 12px;
    opacity: 1 !important;
  }
  &.pointers-event {
    pointer-events: auto;
  }
}
.floating-input {
  border-radius: 0px;
  padding: 7px 5px;
  height: auto;
  vertical-align: middle;
  color: $black;
  margin-bottom: 40px;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  background-image: linear-gradient($light_theme, $light_theme),
    linear-gradient(#909090, #909090);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  &:focus {
    outline: none;
    color: $black;
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
    ~ .floating-label {
      top: -18px;
      color: $light_theme;
      font-size: 12px;
    }
  }
  &:not(:placeholder-shown),
  &:-webkit-autofill {
    ~ .floating-label {
      top: -18px;
      color: $light_theme;
      font-size: 12px;
    }
  }
  &:disabled,
  &[readonly] {
    background-color: #e0e0e0;
    opacity: 0.7;
    ~ .floating-label {
      opacity: 0.7;
    }
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $transparent;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $transparent;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $transparent;
  }
  &::-internal-autofill-selected {
    background-color: #000 !important;
    border-bottom: 1px solid #909090;
    color: #000 !important;
  }
}

.wildcard-custom-form {
  .prefix-label {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0px;
    right: 0;
    overflow: hidden;
    color: $transparent;
    pointer-events: none;
    text-align: left;
    white-space: nowrap;
    font-weight: 500;
    top: unset;
    padding: 7px 5px;
    transition: 0.2s ease all;
    font-size: 14px;
    height: 35px;
    &:after {
      content: '.upscoretestprep.com';
      left: 0px;
      font-size: 14px;
      font-weight: 500;
      color: $black;
      margin-left: 4px;
      display: inline-block;
    }
  }
  .floating-label {
    color: $transparent !important;
    .text-danger {
      color: $transparent !important;
    }
    &.top-label {
      color: $black !important;
      .text-danger {
        color: #e83131 !important;
      }
    }
  }
  .floating-input {
    height: 35px;
    margin-bottom: 0px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #a2a4a7;
      font-weight: 500;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a2a4a7;
      font-weight: 500;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #a2a4a7;
      font-weight: 500;
    }
  }
}

// .custom-input-group {
//   .floating-input {
//     padding-right: 165px;
//   }
//   .custom-input-addon {
//     position: absolute;
//     right: 0px;
//     top: 5px;
//     z-index: 3;
//     .input-group-text {
//       padding: 4px 12px;
//       color: $blue;
//       background-color: #556ee636;
//       border: 0px solid #909090;
//       border-radius: 0px;
//       top: -1px;
//       position: relative;
//     }
//   }
// }

.search-input {
  .floating-input {
    margin-bottom: 4px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $black;
      font-weight: 500;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $black;
      font-weight: 500;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $black;
      font-weight: 500;
    }
  }
}

.subheading-textarea {
  .floating-input {
    height: 123px;
    resize: none;
  }
}

// date picker
.custom-datepicker {
  .react-datepicker-wrapper {
    display: block;
    font-family: 'Avertastd', sans-serif;
  }
  .date-icon {
    position: absolute;
    top: 9px;
    color: #afacac;
    font-size: 15px;
    right: 5px;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    .react-datepicker__triangle {
      border-bottom-color: $light_theme !important;
      &::before {
        top: 1px;
        border-bottom-color: $light_theme !important;
      }
    }
  }
  .react-datepicker-popper[data-placement^='top'] {
    .react-datepicker__triangle {
      &::before {
        border-top-color: $white !important;
      }
    }
  }

  .react-datepicker {
    font-family: 'Avertastd', sans-serif;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    font-size: 12px;
    border: 0px;
    .react-datepicker__navigation--previous {
      border-right-color: $white;
    }
    .react-datepicker__navigation--next {
      border-left-color: $white;
    }

    .react-datepicker__header {
      background-color: $light_theme;
      border-color: $light_theme;
      .react-datepicker__header__dropdown {
        margin-top: 4px;
        select {
          background: #fff;
          border: 0px;
          border-radius: 4px;
          padding: 2px 6px;
        }
      }
    }
    .react-datepicker__current-month {
      color: $white;
      font-weight: 500;
    }
    .react-datepicker__day--keyboard-selected {
      border-radius: 50%;
      background-color: $light_theme;
      color: $white;
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: $white;
      }
    }
    .react-datepicker__day {
      &:hover {
        border-radius: 50%;
        background-color: #d0d0d0;
      }
      &.react-datepicker__day--disabled {
        &:hover {
          background-color: $transparent;
        }
      }
    }
  }
}

.date-filter-tile {
  flex: 1 1 250px !important;
  max-width: 250px !important;
  .DateRangePickerInput__withBorder {
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid #909090;
  }
  .DateInput_input__focused {
    border-bottom: 1px solid #556ee6;
  }
  .CalendarDay__default {
    color: $black;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    color: #cacccd;
  }
  .DayPicker_weekHeader {
    color: $black;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #556ee6;
    border: 1px double #556ee6;
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #556ee6;
    border: 1px double #556ee6;
    color: #fff;
  }
  .CalendarDay__selected_span {
    background: #9dacf5;
    border: 1px double #556ee6;
    color: #fff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #9dacf5;
    border: 1px double #556ee6;
    color: #484848;
  }
  .CalendarMonth_caption {
    padding-top: 16px;

    select {
      font-size: 14px;
      color: #000;
      border: 1px solid #e4e7e7;
      background: #fff;
      padding: 6px 8px;
    }
  }
  .DateInput {
    width: 92px;
    @media (max-width: 575px) {
      width: 85px;
    }
  }
  .DateRangePickerInput_arrow {
    margin-right: 9px;
    @media (max-width: 575px) {
      margin-right: 5px;
    }
    .DateRangePickerInput_arrow_svg {
      fill: #b1a8a8;
      height: 18px;
      width: 18px;
    }
  }
  .DateRangePickerInput_calendarIcon {
    padding: 6px 5px;
    margin: 0px;
    .DateRangePickerInput_calendarIcon_svg {
      fill: $black;
    }
  }
  .DateInput_input {
    padding: 7px 5px;
    color: $black;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $black;
      font-weight: 500;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $black;
      font-weight: 500;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $black;
      font-weight: 500;
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #556ee6;
  }
}

.text-error {
  position: absolute;
  left: 0px;
  bottom: -18px;
  font-size: 11px;
  color: $red;
  &.top {
    top: 35px;
    bottom: unset;
  }
}
.text-error-relative {
  font-size: 11px;
  color: $red;
}
.text-limit {
  position: absolute;
  right: 0px;
  bottom: -22px;
  font-size: 12px;
}
.custom-radio-input {
  .custom-control-label {
    cursor: pointer;
    font-weight: 500;
    &::before {
      border: $grey_variation21 solid 2px;
    }
  }
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $light_theme;
          background-color: $light_theme;
        }
      }
    }
    &:focus {
      & ~ .custom-control-label {
        &::before {
          box-shadow: none;
          border-color: $grey_variation21 !important;
        }
      }
    }

    &:not(:disabled):active {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $light_theme;
          background-color: $light_theme;
        }
      }
    }
  }
}
// form end

// checkbox  start
.custom-checkbox {
  padding-left: 30px;
  .custom-control-label {
    font-weight: 500;
    color: $black;
    cursor: pointer;
    &::before {
      width: 22px;
      height: 22px;
      border: $grey_variation6 solid 2px;
      border-radius: 2px;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      width: 26px;
      height: 26px;
      left: -32px;
      top: calc(50% + 2px);
      transform: translateY(-50%);
    }
  }
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $light_theme;
          background-color: $light_theme;
        }
        &:after {
          background-image: url('../../assets/img/icons/check.svg');
        }
      }
    }
    &:focus {
      & ~ .custom-control-label {
        &::before {
          box-shadow: none;
          border-color: $light_theme !important;
        }
      }
    }

    &:not(:disabled):active {
      & ~ .custom-control-label {
        &::before {
          color: $white;
          border-color: $light_theme;
          background-color: $light_theme;
        }
      }
    }
  }
}
// checkbox  end

// react select
.custom-reactselect {
  .custom-inner-reactselect__placeholder {
    // display: none;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    transition: '0.2s ease all';
  }
  .custom-inner-reactselect__single-value {
    color: #000;
  }
  .custom-inner-reactselect__value-container--is-multi {
    display: flex;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 70px);
  }
  .custom-inner-reactselect__value-container {
    padding: 0px 5px 0px 0px;
  }
  .custom-inner-reactselect__control {
    min-height: 35px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0px;
    padding: 0px;
    height: auto;
    vertical-align: middle;
    color: $black;
    margin-bottom: 40px;
    border: 0;
    background-image: linear-gradient($light_theme, $light_theme),
      linear-gradient(#909090, #909090);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition-duration: 0.3s;
    box-shadow: none;
    &.custom-inner-reactselect__control--is-focused {
      background-size: 100% 2px, 100% 1px;
      transition-duration: 0.3s;
    }
    &.custom-inner-reactselect__control--is-disabled {
      background-color: #e0e0e0;
      opacity: 0.7;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      transition-duration: 0.3s;
    }
    &:hover {
      box-shadow: none;
      // border-color: $light_theme;
    }
  }
  .custom-inner-reactselect__indicator {
    padding: 7px;
  }
  .custom-inner-reactselect__menu {
    z-index: 2;
    color: $black;
    .custom-inner-reactselect__menu-notice--no-options {
      color: $black;
    }
  }
}

//react multislect
.custom-multiselect {
  --rmsc-main: #3271bc !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: rgba(50, 113, 188, 0.11) !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 4px !important; /* Radius */
  --rmsc-h: 35px !important; /* Height */

  .dropdown-container {
    // min-height: 35px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0px;
    padding: 0px;
    height: auto;
    vertical-align: middle;
    color: $black;
    margin-bottom: 10px;
    border: 0 !important;
    background-image: linear-gradient($light_theme, $light_theme),
      linear-gradient(#909090, #909090);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0) !important ;
    transition-duration: 0.3s;
    box-shadow: none !important;
    &:focus-within {
      box-shadow: none !important;
      border-color: transparent;
    }
  }
  [aria-expanded='true'] {
    background-size: 100% 2px, 100% 1px;
    transition-duration: 0.3s;
  }
  .no-options {
    color: $black !important;
  }
  .dropdown-content {
    .search {
      input {
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $black;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $black;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $black;
        }
      }
    }
  }
}

.form-icon {
  .floating-input {
    padding-right: 34px;
  }
  .input-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

// ck editor css
.ck-toolbar {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.ck.ck-button,
a.ck.ck-button {
  line-height: normal !important;
}
.ck-splitbutton {
  display: flex;
}
.ck-content {
  height: 200px;
  overflow: auto;
  border: 1px solid #c4c4c4 !important;
  border-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  ol,
  ul {
    padding-left: 25px;
  }
  p {
    margin-bottom: 6px;
  }
}
.ck-source-editing-area {
  textarea {
    border-top: 0px !important;
    height: 200px;
    overflow: auto;
  }
}
.ck.ck-balloon-panel {
  z-index: 1055 !important;
}
.ck.ck-toolbar-dropdown > .ck-dropdown__panel {
  max-width: 400px !important;

  @media (max-width: 965px) {
    max-width: 300px !important;
  }
}

// btn start
.btn-theme {
  background: $light_theme;
  border: 1px solid $light_theme;
  color: $white;
  padding: 8px 20px;
  min-width: 90px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  transition: all 0.25s ease;
  border-radius: 4px;
  &:hover {
    color: $white;
    background: $blue;
    border-color: $blue;
  }
  &.btn-sm {
    padding: 6px 12px;
    min-width: auto;
    font-size: 14px;
  }
}
.btn-theme-outline {
  background: transparent;
  border: 2px solid $light_theme;
  color: $light_theme;
  padding: 8px 20px;
  min-width: 90px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  transition: all 0.25s ease;
  border-radius: 4px;
  &:hover {
    color: $white;
    background: $light_theme;
    border-color: $light_theme;
  }
}
.btn-theme-secondary {
  color: $white;
  background-color: $grey_variation7;
  border: 1px solid $grey_variation7;
  padding: 8px 20px;
  min-width: 90px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  box-shadow: -1px 2px 12px 2px rgba(116, 120, 141, 0.4);
  transition: all 0.25s ease;
  border-radius: 4px;
  &:hover {
    color: $white;
    background-color: $grey_variation8;
    border-color: $mid_grey_color;
  }
  @media (max-width: 575px) {
    padding: 8px 8px;
    min-width: 80px;
    font-size: 14px;
  }
}
.filter-btn {
  height: 35px;
  width: 35px;
  min-width: auto;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 0px;
  font-size: 16px;
  line-height: normal;
  justify-content: center;
  // border: 0px;
  transition: all 0.3s ease;
  font-family: 'Nunito', sans-serif;
  &.search-btn {
    color: $white;
    background-color: $light_theme;
    border-color: $light_theme;
    &:hover {
      color: $white;
      background-color: $blue;
      border-color: $blue;
      box-shadow: -1px 3px 12px 0px $grey_variation9;
    }
  }
  &.reset-btn {
    margin-left: 10px;
    color: $white;
    background-color: $grey_variation10;
    border-color: $grey_variation10;
    &:hover {
      color: $white;
      background-color: $grey_variation11;
      border-color: $grey_variation11;
      box-shadow: -1px 3px 12px 0px $grey_variation9;
    }
  }
}

.wildcard-list {
  padding: 10px 0px 10px 20px;
  list-style: disc;
  text-align: left;
  margin-bottom: 0px;
  font-size: 11px;
}
.tooltip {
  font-family: 'AvertaStd';
}

.email-tooltip {
  .tooltip-inner {
    max-width: 450px;
  }
}

.image-progress {
  border-radius: 20px;
}

.custom-dropdown {
  min-width: 91px;
  .dropdown-menu {
    min-width: auto;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    border: none;
    padding: 5px 0;
    .dropdown-item {
      padding: 4px 20px;
      color: #000;
      font-size: 12px;
      font-weight: 400;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      border: none;
      &:hover {
        background: #f0f3f5;
        color: #556ee6;
      }
    }
  }
}

.action-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .action-btn {
    font-size: 16px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
    margin: 2px;
    color: #000;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: $grey_variation12;
      color: #000;
    }
  }
  .action-btn-disable {
    cursor: not-allowed;
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 3px;
  letter-spacing: 0.1px;
  min-width: 60px;
}

.tags {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 8px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 13px;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  &.tags-theme {
    background: rgba(85, 110, 230, 0.24);
    color: $light_theme;
  }
}
.nowrap {
  white-space: nowrap;
}
.card {
  height: 100%;
  background-color: $white;
  border: 0;
  border-radius: 4px;
  margin-bottom: 24px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 -3px 31px 0 rgba(18, 38, 63, 0.05),
    0 6px 20px 0 rgba(18, 38, 63, 0.02);
  &.auth-card {
    height: auto;
  }
  .card-header {
    padding: 10px 20px 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 0;
    background-color: $white;
    border-bottom: 1px solid $white_variation5;
    @media (max-width: 575px) {
      flex-flow: column;
    }
    &.dashboard-card-header {
      .card-title {
        flex: 1;
        text-align: left;
        margin-right: 15px;
        @media (max-width: 575px) {
          flex: 0 0 auto;
        }
      }
      .form-group {
        margin-bottom: 0px;
        margin-right: 10px;
        min-width: 200px;
        // max-height: 35px;
        @media (max-width: 575px) {
          margin-bottom: 10px;
        }
        .custom-inner-reactselect__control {
          height: 35px;
          min-height: unset;
          align-items: flex-start;
        }
        .custom-inner-reactselect__indicators {
          height: 33px;
        }
      }
      .custom-form-label {
        display: none;
      }
    }
  }
  .card-title {
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: 700;
    color: $black;
    // @media (max-width: 575px) {
    //   text-align: center;
    // }
  }

  &.view-details-wrap {
    .text-label {
      flex: 0 0 160px;
      max-width: 160px;
      white-space: normal;
    }
  }
}
// btn end

// nodata start
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin: 30px 0px;
  .no-data-icon {
    font-size: 30px;
  }
}
// nodata end
// badge start
.subscription-badge-wrap {
  border-radius: 30px;
  box-shadow: 0px 0px 7px 1px rgba(204, 204, 204, 0.58);
  min-width: 85px;
}
// badge end

//modal start
.modal {
  .common-modal {
    width: 95%;
    margin: 1.75rem auto;
    @media (min-width: 1020px) {
      max-width: 1000px;
    }
    max-width: 1000px;
  }
  .modal-full-width {
    width: 95%;
    max-width: 95%;
    @media (min-width: 1680px) {
      max-width: 1620px;
    }
  }
  .modal-content {
    background-color: $white;
    background-clip: padding-box;
    border: 0 solid transparent;
    border-radius: 0.2rem;
  }
  .modal-header {
    background-color: $white_variation6 !important;
    padding: 1rem 1rem;
    border-bottom: 0 solid $white_variation7;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }
  .close-icon {
    background: transparent;
    box-shadow: none;
    color: $white;
    padding: 0px;
    border: 0px;
    .main-img {
      display: block;
    }
    .hover-img {
      display: none;
    }
    &:hover {
      .main-img {
        display: none;
      }
      .hover-img {
        display: block;
      }
    }
  }
  .info-modal {
    width: auto;
    margin: 1.75rem auto;
    max-width: 700px;
    @media (max-width: 767px) {
      max-width: 500px;
    }
    @media (max-width: 575px) {
      max-width: auto;
      margin: 0.5rem;
    }
    .modal-body {
      padding: 22px 20px;
      p {
        font-size: 16px;
      }
    }
    .close-icon {
      position: absolute;
      top: 5px;
      right: 6px;
      cursor: pointer;
      z-index: 1;
    }
  }
}
//modal end

// tabs design

.custom-nav-tabs {
  border-bottom: 2px solid $white_variation8;
  margin-bottom: 20px;
  .custom-nav-link {
    color: $grey_variation14;
    background-color: transparent;
    border: 0;
    padding: 0.85rem 0;
    margin: 0 2rem;
    border-bottom: 2px solid transparent;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease;
    font-weight: 500;
    @media (max-width: 1080px) {
      margin: 0 1rem;
    }
    img {
      margin-right: 8px;
      width: 20px;
      filter: brightness(0);
    }
    &.active {
      color: $light_theme;
      border-bottom: 2px solid $light_theme;
      background: transparent;
      img {
        filter: brightness(1);
      }
      &:hover {
        color: $light_theme;
        border-bottom: 2px solid $light_theme;
        img {
          filter: brightness(1);
        }
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &.disable-link {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
// detail css start
.info-list {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .text-label {
    font-size: 14px;
    flex: 0 0 160px;
    max-width: 160px;
    font-weight: 700;
    color: $black;
    margin-right: 10px;
    white-space: nowrap;
  }
  .text-value {
    font-size: 14px;
    font-weight: 400;
    color: $black;
    flex: 1;
    word-wrap: break-word;
    word-break: break-word;
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
    .text-label {
      flex: 1 1 100% !important;
      max-width: 100% !important;
    }
  }
}
// detail css end

// text editor css start
.wrapperClassName {
  .toolbarClassName {
    margin-bottom: 0px;
    border-radius: 0px;
    background: $white_variation9;
    border: 0px;
  }
  .editorClassName {
    border: 1px solid $white_variation10;
    border-top: 0px;
    background: $white;
    min-height: 200px;
    padding: 0px 10px;
    max-height: 200px;
    font-size: 14px;

    a {
      font-weight: 400;
      color: $light_theme !important;
      text-decoration: none;
      span {
        color: $light_theme !important;
      }
      &:hover {
        color: $light_theme !important;
        text-decoration: underline;
        span {
          color: $light_theme !important;
        }
      }
    }
  }
  .rdw-image-center {
    justify-content: flex-start;
  }

  .rdw-link-modal {
    height: auto;
    right: 0px;
    left: auto;
    .rdw-link-modal-label,
    .rdw-link-modal-target-option span {
      font-size: 12px;
      font-weight: 400;
      color: $black;
      margin-bottom: 0px;
      padding: 0px;
    }
    .rdw-link-modal-input {
      border-radius: 0px;
      color: $grey_variation1;
      font-size: 12px;
      height: 25px;
      border-color: $grey_variation1;
      padding: 0px 8px;
      line-height: 19px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .rdw-link-modal-buttonsection {
    .rdw-link-modal-btn {
      background: $light_theme;
      border-color: $light_theme;
      color: 12px;
      border-radius: 4px;
      &:hover {
        background: $light_theme;
        box-shadow: none;
      }
      &:last-child {
        background-color: $grey_variation8;
        border: 1px solid $grey_variation8;
        color: white;
        &:hover {
          background-color: $grey_variation8;
          box-shadow: none;
        }
      }
    }
  }
}
// texteditor css end

.select-field-div {
  .custom-reactselect {
    width: 110px;
    margin: 0 auto;
    text-align: left;
  }
  .custom-inner-reactselect__control {
    margin-bottom: 0px !important;
  }
}
// drag & drop start

.dropzone-preview {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 175px;
  min-height: 175px;
  max-width: 100%;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  border: 2px dashed $grey_variation16;
  overflow: hidden;
  color: $grey_variation17;
  font-size: 14px;
  .icon-upload {
    font-size: 50px;
    color: $white_variation10;
  }
  &:focus {
    outline: none;
  }
  span.dropzone-text {
    text-align: center;
    padding: 0px 5px;
  }
}
.preview-img {
  width: auto;
  max-width: 100%;
  height: 170px;
}
// drag & drop end

//inner loader
.loader-wrap {
  min-height: 70px;
  position: relative;
  width: 100%;
}

// sweetalert start
.swal2-container {
  .swal2-title {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    font-family: 'Avertastd', sans-serif;
  }
  .swal2-content {
    color: $black;
    font-size: 14px;
    font-family: 'Avertastd', sans-serif;
  }
  button.swal2-confirm {
    background: #556ee6 !important;
    border: 1px solid #556ee6 !important;
    color: #fff;
    padding: 8px 20px;
    min-width: 90px;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    transition: all 0.25s ease;
    border-radius: 4px;
    box-shadow: none;
  }
  button.swal2-cancel {
    margin-left: 10px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    background-color: #8b8d94 !important;
    border: 1px solid #8b8d94;
    padding: 8px 20px;
    min-width: 90px;
    box-shadow: none;
  }
  .swal2-popup {
    max-width: 350px;
  }
  .swal2-icon {
    margin-top: 5px;
    margin-bottom: 20px;
    width: 3em;
    height: 3em;
    &::before {
      font-size: 2.5em;
    }
  }
  .swal2-actions {
    margin-top: 15px;
  }
  .swal2-close {
    color: $black;
    font-size: 32px;
    &:hover {
      opacity: 0.8;
    }
  }
}
// sweetalert end

// custom scroll
.custom-scroll {
  transition: all 0.5s ease;
  &::-webkit-scrollbar {
    transition: all 0.5s ease;
    width: 7px;
    transition: all 0.5s ease;
    background-color: $white_variation2;
  }
  &::-webkit-scrollbar-thumb {
    transition: all 0.5s ease;
    border-radius: 8px;
    background: $grey_variation11;
  }
  ::-webkit-scrollbar-track {
    border-radius: 7px;
    transition: all 0.5s ease;
    background: $white_variation3;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background: $white_variation3;
    }
    &::-webkit-scrollbar {
      background-color: $white_variation2;
      transition: all 0.5s ease;
    }
    &::-webkit-scrollbar-thumb {
      background: $grey_variation11;
    }
  }
}

// filter start
.filter-form {
  .floating-input,
  .custom-reactselect .custom-inner-reactselect__control {
    margin-bottom: 20px;
  }
}
.filter-field-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .filter-field-tile {
    flex: 1 1 180px;
    max-width: 180px;

    &.user-tile {
      flex: 1 1 270px;
      max-width: 270px;
    }

    &.status-tile {
      flex: 1 1 160px;
      max-width: 160px;
      @media (max-width: 575px) {
        flex: 1 1 50%;
        max-width: 50%;
      }
      @media (max-width: 376px) {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
    @media (max-width: 575px) {
      flex: 1 1 50%;
      max-width: 50%;
    }
    @media (max-width: 376px) {
      flex: 1 1 100%;
      max-width: 100%;
    }
    padding: 0px 7.5px;
    margin-bottom: 20px;
    .form-group {
      margin-bottom: 0px;
    }
  }
  .custom-inner-reactselect__menu-list {
    max-height: 142px;
  }
}
.filter-img {
  width: 24px;
  margin-right: 15px;
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  img {
    width: 100%;
  }
}
// @media (max-width: 575px) {
//   .filter-open-btn {
//     margin: 15px 0px 10px 0px !important;
//   }
// }

.client-img {
  height: 50px;
  object-fit: cover;
}

// filter end

// glowbal media query start
@media (max-width: 991px) {
  html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-sm-show.sidebar-fixed .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .filter-wrap {
    .label-height {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .plan-heading {
    display: none;
  }
  .custom-nav-tabs {
    justify-content: center;
  }
  .custom-nav-tabs .nav-link {
    margin: 0px !important;
    padding: 10px 10px;
    font-size: 13px;
    img {
      margin: 0px;
      width: 25px;
      margin-right: 0px !important;
    }
  }
}
@media (min-height: 575px) {
  .auth-card-container .auth-card-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
// glowbal media query end

// order-tab s
.order-tab {
  .nav-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .nav-link {
      padding-left: 0px;
      padding-right: 0px;
      font-weight: 600;
      border-radius: 0px !important;
      border-bottom: 1px solid transparent;
      font-size: 16px;
      user-select: none;
      img {
        filter: brightness(0) invert(0);
      }
      &:first-child {
        margin-right: 15px;
      }
      &.active {
        border-bottom: 1px solid #556ee6;
        color: #556ee6;

        img {
          filter: none;
        }
      }
      border-radius: 5px;
      border-width: 0px 0px 1px 0px;
    }
  }
  .tab-pane,
  .tab-content {
    border: none;
  }
  .tab-pane {
    padding: 15px 0px 0px 0px;
  }
  .name-title {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }
  .other-details-name {
    font-size: 12px;
    font-weight: normal;
  }
}
// order-tab e
// modal view
.info-m-list.w-100 {
  flex: 0 0 100%;
  max-width: 100%;
}
.info-m-list {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex: 0 0 33%;
  max-width: 33%;
  padding: 0px 10px;
  @media (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .text-label {
    font-size: 14px;
    flex: 0 0 160px;
    max-width: 160px;
    font-weight: 700;
    color: $black;
    margin-right: 10px;
    white-space: nowrap;
  }
  .text-value {
    font-size: 14px;
    font-weight: 400;
    color: $black;
    flex: 1;
  }
  .icon-wrap {
    flex: 0 0 40px;
    max-width: 40px;
    color: #33d197;
  }
}

.subscription-tag {
  padding: 4px 6px;
  background: rgba(85, 110, 230, 0.22);
  margin: 2px;
  display: inline-block;
  color: #000;
  border-radius: 5px;
  font-size: 12px;
}
.col-cus {
  @media (max-width: 1040px) {
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media (max-width: 767px) {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 575px) {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.badge-wrapper {
  .card {
    .card-header {
      @media (max-width: 1080px) {
        padding: 10px 10px 10px;
      }
    }
  }
}

.main-video-wrap {
  padding: 5px;
  background: #ddd;
  margin-bottom: 15px;
  text-align: center;
  height: 175px;
  @media (max-width: 575px) {
    height: auto;
  }
  video {
    height: 100%;
    @media (max-width: 575px) {
      height: auto;
      width: 100%;
    }
  }
}

.email-wrap {
  position: relative;
  padding-right: 25px;
  .email-info-icon {
    position: absolute;
    top: 7px;
    right: 0px;
  }
}

.table.table-align-top {
  tr {
    td {
      @media (max-width: 767px) {
        vertical-align: top;
      }
    }
  }
}

.custom-pagination {
  ul {
    flex-wrap: wrap;
    .page-item {
      margin-bottom: 8px;
    }
  }
}
