// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@font-face {
  font-family: 'AvertaStd';
  font-style: normal;
  src: url('assets/fonts/AvertaStd/avertastd-regular.woff') format('woff'),
    url('assets/fonts/AvertaStd/avertastd-regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  font-weight: 500;
  src: url('assets/fonts/AvertaStd/avertastd-semibold.woff') format('woff'),
    url('assets/fonts/AvertaStd/avertastd-semibold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'AvertaStd';
  font-weight: bold;
  src: url('assets/fonts/AvertaStd/avertastd-bold.woff') format('woff'),
    url('assets/fonts/AvertaStd/avertastd-bold.otf') format('opentype');
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Avertastd',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Avertastd', sans-serif;
  font-size: 14px;
  background: $white_variation1;
  color: $black;
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .brand-logo {
    display: inline-block;
    min-width: 250px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    @media (max-width: 991px) {
      text-align: left;
    }
    @media (max-width: 576px) {
      min-width: 73px;
      img {
        height: 35px;
      }
    }
  }
}
