// Variable overrides
$transparent: transparent;
$primary: #556ee6;
$success: #34c38f;
$info: #50a5f1;
$warning: #f1b44c;
$danger: #f46a6a;
$light: #f8f9fa;
$dark: #343a40;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-25: 25px;
$font-size-28: 28px;

$white_variation1: #f3f3f5;
$white_variation2: #f1f1f1;
$white_variation3: #dddddd;
$white_variation4: #e5e6ea;
$gray_light_main: #fdfdfd;
$grey_variation1: #333;
$grey_variation2: #999;
$grey_variation3: #eee;
$gray: #bbb;
$white: #fff;
$black: #000;
$light_black: #232323;
$grey_variation4: #dcdcdc;
$light_theme: #556ee6;
$red: #e83131;

$grey_variation5: #6a707e;
$grey_variation6: #ced4da;
$main_light_black: #495057;
$grey_variation7: #56575a;
$grey_variation8: #636678;
$mid_grey_color: #5d6071;
$blue: #2a43bd;
$grey_variation9: #5858584f;
$grey_variation10: #8b8d94;
$grey_variation11: #a5a8b1;
$grey_variation12: #e0e0e0;
$grey_variation13: #444;
$white_variation5: #e6e2e2;
$white_variation6: #f3f7f9;
$white_variation7: #e5e8eb;
$white_variation8: #e4e6ef;
$grey_variation14: #7e8299;
$grey_variation15: #74788d;
$white_variation9: #e6e4e4;
$white_variation10: #dcddde;
$black_color: #000;
$grey_variation16: #b7b7b7;
$grey_variation17: #b5b5b5;
$white_variation10: #c7c7c7;
$white_variation12: #fffffff0;
$grey_variation18: #555b6d;
$grey_variation19: #34393e;
$grey_variation20: #212529;
$grey_variation21: #aaaeb9;
$white_variation11: #f2f2f5;
$base_color: #556ee5;
$black_variation1: #1d2335;
$black_variation2: #2a3042;
$grey_variation22: #a0aec0;
$grey_variation23: #6a7187;
$grey_variation24: #79829c;
$green_color: #1abc9c;
$white_variation12: #f5f8f9;
$white_variation13: #d6dcea;
$dark_blue_color: #2b3774;
$grey_variation25: #ccc;
$white_variation14: #d3dae9;
$light_blue_color: #4867aa;
$dark_blue_color: #1a3353;
$grey_variation26: #c2c7cc;
$white_variation27: #e5e6ea;
